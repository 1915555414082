import {Form, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {CrudFormProps} from "../crudForm/crudFormProps";
import CrudForm from "../crudForm/crudForm";

export default function CrudFormModal<TSubmit, TInitial>(props: CrudFormModalProps<TSubmit, TInitial>) {
    const form = props.form ? props.form : Form.useForm()[0];
    const [isLoading, setIsLoading] = useState(false);

    const onModalOkClicked = async () => {
        setIsLoading(true);
        form.submit();
    }

    const onModalCancelClicked = () => {
        props.onModalCancelClicked?.();
    }

    const onModalClosed = () => {
        form.resetFields();
    }

    useEffect(() => {
        form.resetFields();
    }, [props.isModalVisible, form]);

    return <>
        <Modal
            visible={props.isModalVisible}
            title={props.modalTitle}
            onOk={async () => await onModalOkClicked()}
            okButtonProps={{
                loading: isLoading,
                disabled: isLoading
            }}
            okText={props.modalOkText || "Save"}
            onCancel={onModalCancelClicked}
            cancelText={props.modalCancelText || "Cancel"}
            afterClose={onModalClosed}
            width={props?.width}
        >
            <CrudForm
                {...props}
                onSubmit={props.onSubmit}
                onFinishFailed={() => setIsLoading(false)}
                form={form}
                tailProps={{hidden: true}}
                setIsLoadingExternal={v => setIsLoading(v)}
            />
        </Modal>
    </>;
}


export interface CrudFormModalProps<TSubmit, TInitial> extends CrudFormProps<TSubmit, TInitial> {
    isModalVisible: boolean;
    modalOkText?: string,
    modalCancelText?: string,
    onModalCancelClicked?: () => any;
    modalTitle: string;
    shouldClearModalOnClose?: boolean;
    width?: number
}
