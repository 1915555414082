import { useAuth } from "oidc-react";
import { useSelector } from "react-redux";
import { ApplicationUserRole } from "../api";
import { getCurrentUser } from "../redux/applicationUserSlice";

export default function useCurrentUserRoles(): ApplicationUserRole[] {
    const auth = useAuth();
    const currentUser = useSelector(getCurrentUser);

    if (!auth.userData || !auth.userData.profile.applicationUserRoles) {
        return [];
    }

    if (currentUser) {
        return currentUser.roles!;
    }

    const roles = auth.userData.profile.applicationUserRoles;

    return (Array.isArray(roles) ? roles : [roles]) as ApplicationUserRole[];
}
