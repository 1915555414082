/* tslint:disable */
/* eslint-disable */
/**
 * Digimuth.StalowaWola.Audiotour.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApplicationUserActivationDto
 */
export interface ApplicationUserActivationDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDetailsDto
 */
export interface ApplicationUserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserDetailsDto
     */
    roles?: Array<ApplicationUserRole> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDto
 */
export interface ApplicationUserDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    emailConfirmed?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationUserRegisterDto
 */
export interface ApplicationUserRegisterDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    withInvitation?: boolean;
    /**
     * 
     * @type {ApplicationUserRole}
     * @memberof ApplicationUserRegisterDto
     */
    role?: ApplicationUserRole;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationUserRole {
    Admin = 'Admin'
}

/**
 * 
 * @export
 * @interface AudioFileDetailsDto
 */
export interface AudioFileDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof AudioFileDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AudioFileDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AudioFileDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AudioFileDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AudioFileDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileDetailsDto
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileDetailsDto
     */
    blobStorageName?: string | null;
}
/**
 * 
 * @export
 * @interface AudioFileDto
 */
export interface AudioFileDto {
    /**
     * 
     * @type {number}
     * @memberof AudioFileDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AudioFileDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AudioFileDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AudioFileDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AudioFileDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileDto
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileDto
     */
    blobStorageName?: string | null;
}
/**
 * 
 * @export
 * @interface AudioFileUpdateDto
 */
export interface AudioFileUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof AudioFileUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AudioFileUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AudioFileUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AudioFileUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AudioFileUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileUpdateDto
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AudioFileUpdateDto
     */
    blobStorageName?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Direction {
    Up = 'Up',
    Down = 'Down'
}

/**
 * 
 * @export
 * @interface EducationPointCoverPictureDetailsDto
 */
export interface EducationPointCoverPictureDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointCoverPictureDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointCoverPictureDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointCoverPictureDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointCoverPictureDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointCoverPictureDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EducationPointCoverPictureDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EducationPointCoverPictureDetailsDto
     */
    contentType?: string | null;
}
/**
 * 
 * @export
 * @interface EducationPointCoverPictureDto
 */
export interface EducationPointCoverPictureDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointCoverPictureDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointCoverPictureDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointCoverPictureDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointCoverPictureDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointCoverPictureDto
     */
    updatedById?: number | null;
}
/**
 * 
 * @export
 * @interface EducationPointCoverPictureUpdateDto
 */
export interface EducationPointCoverPictureUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointCoverPictureUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointCoverPictureUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointCoverPictureUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointCoverPictureUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointCoverPictureUpdateDto
     */
    updatedById?: number | null;
}
/**
 * 
 * @export
 * @interface EducationPointCreateDto
 */
export interface EducationPointCreateDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointCreateDto
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointCreateDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointCreateDto
     */
    coverPictureId?: number | null;
    /**
     * 
     * @type {Array<EducationPointSectionDto>}
     * @memberof EducationPointCreateDto
     */
    educationPointSections?: Array<EducationPointSectionDto> | null;
}
/**
 * 
 * @export
 * @interface EducationPointDetailsDto
 */
export interface EducationPointDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointDetailsDto
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointDetailsDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointDetailsDto
     */
    coverPictureId?: number | null;
    /**
     * 
     * @type {Array<EducationPointSectionDto>}
     * @memberof EducationPointDetailsDto
     */
    educationPointSections?: Array<EducationPointSectionDto> | null;
}
/**
 * 
 * @export
 * @interface EducationPointDto
 */
export interface EducationPointDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointDto
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointDto
     */
    coverPictureId?: number | null;
}
/**
 * 
 * @export
 * @interface EducationPointPictureDetailsDto
 */
export interface EducationPointPictureDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureDetailsDto
     */
    contentType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureDetailsDto
     */
    educationPointSectionId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureDetailsDto
     */
    position?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureDetailsDto
     */
    paragraphsToSkip?: number;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointPictureDetailsDto
     */
    description?: Array<LocalizableStringDto> | null;
}
/**
 * 
 * @export
 * @interface EducationPointPictureDto
 */
export interface EducationPointPictureDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureDto
     */
    position?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureDto
     */
    paragraphsToSkip?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureDto
     */
    educationPointSectionId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointPictureDto
     */
    description?: Array<LocalizableStringDto> | null;
}
/**
 * 
 * @export
 * @interface EducationPointPictureInfoDto
 */
export interface EducationPointPictureInfoDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureInfoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureInfoDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureInfoDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureInfoDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureInfoDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureInfoDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureInfoDto
     */
    position?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureInfoDto
     */
    paragraphsToSkip?: number;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointPictureInfoDto
     */
    description?: Array<LocalizableStringDto> | null;
}
/**
 * 
 * @export
 * @interface EducationPointPictureReducedDto
 */
export interface EducationPointPictureReducedDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureReducedDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureReducedDto
     */
    position?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureReducedDto
     */
    paragraphsToSkip?: number;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointPictureReducedDto
     */
    description?: Array<LocalizableStringDto> | null;
}
/**
 * 
 * @export
 * @interface EducationPointPictureUpdateDto
 */
export interface EducationPointPictureUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureUpdateDto
     */
    position?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureUpdateDto
     */
    paragraphsToSkip?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointPictureUpdateDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointPictureUpdateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointPictureUpdateDto
     */
    educationPointSectionId?: number | null;
}
/**
 * 
 * @export
 * @interface EducationPointSectionCreateDto
 */
export interface EducationPointSectionCreateDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointSectionCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointSectionCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionCreateDto
     */
    educationPointId?: number;
    /**
     * 
     * @type {Array<Int64LocalizableEntityDto>}
     * @memberof EducationPointSectionCreateDto
     */
    audio?: Array<Int64LocalizableEntityDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointSectionCreateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointSectionCreateDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EducationPointSectionCreateDto
     */
    educationPointPicturesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<EducationPointPictureReducedDto>}
     * @memberof EducationPointSectionCreateDto
     */
    educationPointPicutres?: Array<EducationPointPictureReducedDto> | null;
}
/**
 * 
 * @export
 * @interface EducationPointSectionDetailsDto
 */
export interface EducationPointSectionDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointSectionDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointSectionDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionDetailsDto
     */
    educationPointId?: number;
    /**
     * 
     * @type {Array<Int64LocalizableEntityDto>}
     * @memberof EducationPointSectionDetailsDto
     */
    audio?: Array<Int64LocalizableEntityDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointSectionDetailsDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointSectionDetailsDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EducationPointSectionDetailsDto
     */
    educationPointPicturesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<EducationPointPictureReducedDto>}
     * @memberof EducationPointSectionDetailsDto
     */
    educationPointPicutres?: Array<EducationPointPictureReducedDto> | null;
}
/**
 * 
 * @export
 * @interface EducationPointSectionDto
 */
export interface EducationPointSectionDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointSectionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointSectionDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionDto
     */
    educationPointId?: number;
    /**
     * 
     * @type {Array<Int64LocalizableEntityDto>}
     * @memberof EducationPointSectionDto
     */
    audio?: Array<Int64LocalizableEntityDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointSectionDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointSectionDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EducationPointSectionDto
     */
    educationPointPicturesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<EducationPointPictureReducedDto>}
     * @memberof EducationPointSectionDto
     */
    educationPointPicutres?: Array<EducationPointPictureReducedDto> | null;
}
/**
 * 
 * @export
 * @interface EducationPointSectionUpdateDto
 */
export interface EducationPointSectionUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointSectionUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointSectionUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointSectionUpdateDto
     */
    educationPointId?: number;
    /**
     * 
     * @type {Array<Int64LocalizableEntityDto>}
     * @memberof EducationPointSectionUpdateDto
     */
    audio?: Array<Int64LocalizableEntityDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointSectionUpdateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointSectionUpdateDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EducationPointSectionUpdateDto
     */
    educationPointPicturesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<EducationPointPictureReducedDto>}
     * @memberof EducationPointSectionUpdateDto
     */
    educationPointPicutres?: Array<EducationPointPictureReducedDto> | null;
}
/**
 * 
 * @export
 * @interface EducationPointTrailMinimalDto
 */
export interface EducationPointTrailMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointTrailMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointTrailMinimalDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointTrailMinimalDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointTrailMinimalDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointTrailMinimalDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointTrailMinimalDto
     */
    educationPointId?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointTrailMinimalDto
     */
    educationPointPosition?: number;
    /**
     * 
     * @type {EducationPointDto}
     * @memberof EducationPointTrailMinimalDto
     */
    educationPoint?: EducationPointDto;
}
/**
 * 
 * @export
 * @interface EducationPointUpdateDto
 */
export interface EducationPointUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof EducationPointUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EducationPointUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EducationPointUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EducationPointUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EducationPointUpdateDto
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointUpdateDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationPointUpdateDto
     */
    coverPictureId?: number | null;
    /**
     * 
     * @type {Array<EducationPointSectionDto>}
     * @memberof EducationPointUpdateDto
     */
    educationPointSections?: Array<EducationPointSectionDto> | null;
}
/**
 * 
 * @export
 * @interface EndForgotPasswordDto
 */
export interface EndForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    newPassword?: string | null;
}
/**
 * 
 * @export
 * @interface Int64LocalizableEntityDto
 */
export interface Int64LocalizableEntityDto {
    /**
     * 
     * @type {string}
     * @memberof Int64LocalizableEntityDto
     */
    locale?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Int64LocalizableEntityDto
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface LocalizableStringDto
 */
export interface LocalizableStringDto {
    /**
     * 
     * @type {string}
     * @memberof LocalizableStringDto
     */
    locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocalizableStringDto
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoginDto
     */
    rememberMe?: boolean;
}
/**
 * 
 * @export
 * @interface MonumentCreateDto
 */
export interface MonumentCreateDto {
    /**
     * 
     * @type {number}
     * @memberof MonumentCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MonumentCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof MonumentCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentCreateDto
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentCreateDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentCreateDto
     */
    monumentIconId?: number;
    /**
     * 
     * @type {MonumentIconDto}
     * @memberof MonumentCreateDto
     */
    monumentIcon?: MonumentIconDto;
    /**
     * 
     * @type {number}
     * @memberof MonumentCreateDto
     */
    educationPointId?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentCreateDto
     */
    position?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MonumentCreateDto
     */
    largeVariant?: boolean;
}
/**
 * 
 * @export
 * @interface MonumentDetailsDto
 */
export interface MonumentDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof MonumentDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MonumentDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof MonumentDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentDetailsDto
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentDetailsDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentDetailsDto
     */
    monumentIconId?: number;
    /**
     * 
     * @type {MonumentIconDto}
     * @memberof MonumentDetailsDto
     */
    monumentIcon?: MonumentIconDto;
    /**
     * 
     * @type {number}
     * @memberof MonumentDetailsDto
     */
    educationPointId?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentDetailsDto
     */
    position?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MonumentDetailsDto
     */
    largeVariant?: boolean;
}
/**
 * 
 * @export
 * @interface MonumentDto
 */
export interface MonumentDto {
    /**
     * 
     * @type {number}
     * @memberof MonumentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MonumentDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof MonumentDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentDto
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentDto
     */
    monumentIconId?: number;
    /**
     * 
     * @type {MonumentIconDto}
     * @memberof MonumentDto
     */
    monumentIcon?: MonumentIconDto;
    /**
     * 
     * @type {number}
     * @memberof MonumentDto
     */
    educationPointId?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentDto
     */
    position?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MonumentDto
     */
    largeVariant?: boolean;
}
/**
 * 
 * @export
 * @interface MonumentIconDetailsDto
 */
export interface MonumentIconDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof MonumentIconDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentIconDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MonumentIconDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentIconDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentIconDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MonumentIconDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonumentIconDetailsDto
     */
    contentType?: string | null;
}
/**
 * 
 * @export
 * @interface MonumentIconDto
 */
export interface MonumentIconDto {
    /**
     * 
     * @type {number}
     * @memberof MonumentIconDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentIconDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MonumentIconDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentIconDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentIconDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MonumentIconDto
     */
    fileName?: string | null;
}
/**
 * 
 * @export
 * @interface MonumentIconUpdateDto
 */
export interface MonumentIconUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof MonumentIconUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentIconUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MonumentIconUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentIconUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentIconUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MonumentIconUpdateDto
     */
    fileName?: string | null;
}
/**
 * 
 * @export
 * @interface MonumentReducedDto
 */
export interface MonumentReducedDto {
    /**
     * 
     * @type {number}
     * @memberof MonumentReducedDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentReducedDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MonumentReducedDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentReducedDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentReducedDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof MonumentReducedDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentReducedDto
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentReducedDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentReducedDto
     */
    monumentIconId?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentReducedDto
     */
    position?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MonumentReducedDto
     */
    largeVariant?: boolean;
}
/**
 * 
 * @export
 * @interface MonumentUpdateDto
 */
export interface MonumentUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof MonumentUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MonumentUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MonumentUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof MonumentUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MonumentUpdateDto
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentUpdateDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentUpdateDto
     */
    monumentIconId?: number;
    /**
     * 
     * @type {MonumentIconDto}
     * @memberof MonumentUpdateDto
     */
    monumentIcon?: MonumentIconDto;
    /**
     * 
     * @type {number}
     * @memberof MonumentUpdateDto
     */
    educationPointId?: number;
    /**
     * 
     * @type {number}
     * @memberof MonumentUpdateDto
     */
    position?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MonumentUpdateDto
     */
    largeVariant?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationType {
    Add = 'Add',
    Remove = 'Remove',
    Replace = 'Replace',
    Move = 'Move',
    Copy = 'Copy',
    Test = 'Test',
    Invalid = 'Invalid'
}

/**
 * 
 * @export
 * @interface StartForgotPasswordDto
 */
export interface StartForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof StartForgotPasswordDto
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @interface TrailCoordinateDto
 */
export interface TrailCoordinateDto {
    /**
     * 
     * @type {number}
     * @memberof TrailCoordinateDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof TrailCoordinateDto
     */
    longitude?: number;
}
/**
 * 
 * @export
 * @interface TrailCreateDto
 */
export interface TrailCreateDto {
    /**
     * 
     * @type {number}
     * @memberof TrailCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TrailCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TrailCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TrailCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TrailCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof TrailCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof TrailCreateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof TrailCreateDto
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrailCreateDto
     */
    icon?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TrailCreateDto
     */
    educationPointsIds?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof TrailCreateDto
     */
    kilometerLength?: number;
    /**
     * 
     * @type {number}
     * @memberof TrailCreateDto
     */
    walkingTime?: number;
    /**
     * 
     * @type {number}
     * @memberof TrailCreateDto
     */
    cyclingTime?: number;
    /**
     * 
     * @type {Array<TrailCoordinateDto>}
     * @memberof TrailCreateDto
     */
    track?: Array<TrailCoordinateDto> | null;
    /**
     * 
     * @type {Array<EducationPointTrailMinimalDto>}
     * @memberof TrailCreateDto
     */
    educationPoints?: Array<EducationPointTrailMinimalDto> | null;
}
/**
 * 
 * @export
 * @interface TrailDetailsDto
 */
export interface TrailDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof TrailDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TrailDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TrailDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TrailDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TrailDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof TrailDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof TrailDetailsDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof TrailDetailsDto
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrailDetailsDto
     */
    icon?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TrailDetailsDto
     */
    educationPointsIds?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof TrailDetailsDto
     */
    kilometerLength?: number;
    /**
     * 
     * @type {number}
     * @memberof TrailDetailsDto
     */
    walkingTime?: number;
    /**
     * 
     * @type {number}
     * @memberof TrailDetailsDto
     */
    cyclingTime?: number;
    /**
     * 
     * @type {Array<TrailCoordinateDto>}
     * @memberof TrailDetailsDto
     */
    track?: Array<TrailCoordinateDto> | null;
    /**
     * 
     * @type {Array<EducationPointTrailMinimalDto>}
     * @memberof TrailDetailsDto
     */
    educationPoints?: Array<EducationPointTrailMinimalDto> | null;
}
/**
 * 
 * @export
 * @interface TrailDto
 */
export interface TrailDto {
    /**
     * 
     * @type {number}
     * @memberof TrailDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TrailDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TrailDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TrailDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TrailDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof TrailDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof TrailDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof TrailDto
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrailDto
     */
    icon?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TrailDto
     */
    educationPointsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<TrailCoordinateDto>}
     * @memberof TrailDto
     */
    track?: Array<TrailCoordinateDto> | null;
    /**
     * 
     * @type {number}
     * @memberof TrailDto
     */
    kilometerLength?: number;
    /**
     * 
     * @type {number}
     * @memberof TrailDto
     */
    walkingTime?: number;
    /**
     * 
     * @type {number}
     * @memberof TrailDto
     */
    cyclingTime?: number;
}
/**
 * 
 * @export
 * @interface TrailUpdateDto
 */
export interface TrailUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof TrailUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TrailUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TrailUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TrailUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TrailUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof TrailUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof TrailUpdateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof TrailUpdateDto
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrailUpdateDto
     */
    icon?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TrailUpdateDto
     */
    educationPointsIds?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof TrailUpdateDto
     */
    kilometerLength?: number;
    /**
     * 
     * @type {number}
     * @memberof TrailUpdateDto
     */
    walkingTime?: number;
    /**
     * 
     * @type {number}
     * @memberof TrailUpdateDto
     */
    cyclingTime?: number;
    /**
     * 
     * @type {Array<TrailCoordinateDto>}
     * @memberof TrailUpdateDto
     */
    track?: Array<TrailCoordinateDto> | null;
    /**
     * 
     * @type {Array<EducationPointTrailMinimalDto>}
     * @memberof TrailUpdateDto
     */
    educationPoints?: Array<EducationPointTrailMinimalDto> | null;
}

/**
 * ApplicationUserApi - axios parameter creator
 * @export
 */
export const ApplicationUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost: async (applicationUserActivationDto?: ApplicationUserActivationDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserActivationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserBeginForgotPasswordPost: async (startForgotPasswordDto?: StartForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/beginForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEndForgotPasswordPost: async (endForgotPasswordDto?: EndForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/endForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdDelete', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPatch', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPut', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost: async (id: number, applicationUserRole?: Array<ApplicationUserRole>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdRolesPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost: async (applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationUserApi - functional programming interface
 * @export
 */
export const ApplicationUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserActivatePost(applicationUserActivationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPut(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdRolesPost(id, applicationUserRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserPost(applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationUserApi - factory interface
 * @export
 */
export const ApplicationUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationUserApiFp(configuration)
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet(id: number, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationUserApi - object-oriented interface
 * @export
 * @class ApplicationUserApi
 * @extends {BaseAPI}
 */
export class ApplicationUserApi extends BaseAPI {
    /**
     * 
     * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdDelete(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<ApplicationUserRole>} [applicationUserRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AudioFileApi - axios parameter creator
 * @export
 */
export const AudioFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AudioFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudioFileIdDelete', 'id', id)
            const localVarPath = `/api/AudioFile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileIdFileStreamGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudioFileIdFileStreamGet', 'id', id)
            const localVarPath = `/api/AudioFile/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudioFileIdGet', 'id', id)
            const localVarPath = `/api/AudioFile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AudioFileUpdateDto} [audioFileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileIdPatch: async (id: number, audioFileUpdateDto?: AudioFileUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudioFileIdPatch', 'id', id)
            const localVarPath = `/api/AudioFile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(audioFileUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AudioFileUpdateDto} [audioFileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileIdPut: async (id: number, audioFileUpdateDto?: AudioFileUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAudioFileIdPut', 'id', id)
            const localVarPath = `/api/AudioFile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(audioFileUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFilePost: async (file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AudioFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AudioFileApi - functional programming interface
 * @export
 */
export const AudioFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AudioFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioFileGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AudioFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioFileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioFileIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioFileIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioFileIdFileStreamGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioFileIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioFileIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudioFileDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioFileIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AudioFileUpdateDto} [audioFileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioFileIdPatch(id: number, audioFileUpdateDto?: AudioFileUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudioFileDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioFileIdPatch(id, audioFileUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AudioFileUpdateDto} [audioFileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioFileIdPut(id: number, audioFileUpdateDto?: AudioFileUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudioFileDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioFileIdPut(id, audioFileUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAudioFilePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AudioFileDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAudioFilePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AudioFileApi - factory interface
 * @export
 */
export const AudioFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AudioFileApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileGet(options?: any): AxiosPromise<Array<AudioFileDto>> {
            return localVarFp.apiAudioFileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAudioFileIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAudioFileIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileIdGet(id: number, options?: any): AxiosPromise<AudioFileDetailsDto> {
            return localVarFp.apiAudioFileIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AudioFileUpdateDto} [audioFileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileIdPatch(id: number, audioFileUpdateDto?: AudioFileUpdateDto, options?: any): AxiosPromise<AudioFileDetailsDto> {
            return localVarFp.apiAudioFileIdPatch(id, audioFileUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AudioFileUpdateDto} [audioFileUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFileIdPut(id: number, audioFileUpdateDto?: AudioFileUpdateDto, options?: any): AxiosPromise<AudioFileDetailsDto> {
            return localVarFp.apiAudioFileIdPut(id, audioFileUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAudioFilePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<AudioFileDetailsDto> {
            return localVarFp.apiAudioFilePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AudioFileApi - object-oriented interface
 * @export
 * @class AudioFileApi
 * @extends {BaseAPI}
 */
export class AudioFileApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioFileApi
     */
    public apiAudioFileGet(options?: any) {
        return AudioFileApiFp(this.configuration).apiAudioFileGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioFileApi
     */
    public apiAudioFileIdDelete(id: number, options?: any) {
        return AudioFileApiFp(this.configuration).apiAudioFileIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioFileApi
     */
    public apiAudioFileIdFileStreamGet(id: number, options?: any) {
        return AudioFileApiFp(this.configuration).apiAudioFileIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioFileApi
     */
    public apiAudioFileIdGet(id: number, options?: any) {
        return AudioFileApiFp(this.configuration).apiAudioFileIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AudioFileUpdateDto} [audioFileUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioFileApi
     */
    public apiAudioFileIdPatch(id: number, audioFileUpdateDto?: AudioFileUpdateDto, options?: any) {
        return AudioFileApiFp(this.configuration).apiAudioFileIdPatch(id, audioFileUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AudioFileUpdateDto} [audioFileUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioFileApi
     */
    public apiAudioFileIdPut(id: number, audioFileUpdateDto?: AudioFileUpdateDto, options?: any) {
        return AudioFileApiFp(this.configuration).apiAudioFileIdPut(id, audioFileUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudioFileApi
     */
    public apiAudioFilePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return AudioFileApiFp(this.configuration).apiAudioFilePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginCallbackGet: async (returnUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/loginCallback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginGet: async (loginPageUrl?: string, returnUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (loginPageUrl !== undefined) {
                localVarQueryParameter['loginPageUrl'] = loginPageUrl;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost: async (returnUrl?: string, loginDto?: LoginDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLogoutGet: async (postLogoutUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (postLogoutUrl !== undefined) {
                localVarQueryParameter['postLogoutUrl'] = postLogoutUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginCallbackGet(returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginGet(loginPageUrl, returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginPost(returnUrl, loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLogoutGet(postLogoutUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginCallbackGet(returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginGet(loginPageUrl, returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginPost(returnUrl, loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLogoutGet(postLogoutUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginCallbackGet(returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [loginPageUrl] 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginGet(loginPageUrl, returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnUrl] 
     * @param {LoginDto} [loginDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginPost(returnUrl, loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [postLogoutUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLogoutGet(postLogoutUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EducationPointApi - axios parameter creator
 * @export
 */
export const EducationPointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EducationPoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointIdDelete', 'id', id)
            const localVarPath = `/api/EducationPoint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointIdGet', 'id', id)
            const localVarPath = `/api/EducationPoint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointUpdateDto} [educationPointUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointIdPatch: async (id: number, educationPointUpdateDto?: EducationPointUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointIdPatch', 'id', id)
            const localVarPath = `/api/EducationPoint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationPointUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointUpdateDto} [educationPointUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointIdPut: async (id: number, educationPointUpdateDto?: EducationPointUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointIdPut', 'id', id)
            const localVarPath = `/api/EducationPoint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationPointUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EducationPointCreateDto} [educationPointCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPost: async (educationPointCreateDto?: EducationPointCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EducationPoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationPointCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EducationPointApi - functional programming interface
 * @export
 */
export const EducationPointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EducationPointApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EducationPointDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointUpdateDto} [educationPointUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointIdPatch(id: number, educationPointUpdateDto?: EducationPointUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointIdPatch(id, educationPointUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointUpdateDto} [educationPointUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointIdPut(id: number, educationPointUpdateDto?: EducationPointUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointIdPut(id, educationPointUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EducationPointCreateDto} [educationPointCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointPost(educationPointCreateDto?: EducationPointCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointPost(educationPointCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EducationPointApi - factory interface
 * @export
 */
export const EducationPointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EducationPointApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointGet(options?: any): AxiosPromise<Array<EducationPointDto>> {
            return localVarFp.apiEducationPointGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEducationPointIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointIdGet(id: number, options?: any): AxiosPromise<EducationPointDetailsDto> {
            return localVarFp.apiEducationPointIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointUpdateDto} [educationPointUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointIdPatch(id: number, educationPointUpdateDto?: EducationPointUpdateDto, options?: any): AxiosPromise<EducationPointDetailsDto> {
            return localVarFp.apiEducationPointIdPatch(id, educationPointUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointUpdateDto} [educationPointUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointIdPut(id: number, educationPointUpdateDto?: EducationPointUpdateDto, options?: any): AxiosPromise<EducationPointDetailsDto> {
            return localVarFp.apiEducationPointIdPut(id, educationPointUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EducationPointCreateDto} [educationPointCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPost(educationPointCreateDto?: EducationPointCreateDto, options?: any): AxiosPromise<EducationPointDetailsDto> {
            return localVarFp.apiEducationPointPost(educationPointCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EducationPointApi - object-oriented interface
 * @export
 * @class EducationPointApi
 * @extends {BaseAPI}
 */
export class EducationPointApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointApi
     */
    public apiEducationPointGet(options?: any) {
        return EducationPointApiFp(this.configuration).apiEducationPointGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointApi
     */
    public apiEducationPointIdDelete(id: number, options?: any) {
        return EducationPointApiFp(this.configuration).apiEducationPointIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointApi
     */
    public apiEducationPointIdGet(id: number, options?: any) {
        return EducationPointApiFp(this.configuration).apiEducationPointIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EducationPointUpdateDto} [educationPointUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointApi
     */
    public apiEducationPointIdPatch(id: number, educationPointUpdateDto?: EducationPointUpdateDto, options?: any) {
        return EducationPointApiFp(this.configuration).apiEducationPointIdPatch(id, educationPointUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EducationPointUpdateDto} [educationPointUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointApi
     */
    public apiEducationPointIdPut(id: number, educationPointUpdateDto?: EducationPointUpdateDto, options?: any) {
        return EducationPointApiFp(this.configuration).apiEducationPointIdPut(id, educationPointUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EducationPointCreateDto} [educationPointCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointApi
     */
    public apiEducationPointPost(educationPointCreateDto?: EducationPointCreateDto, options?: any) {
        return EducationPointApiFp(this.configuration).apiEducationPointPost(educationPointCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EducationPointCoverPictureApi - axios parameter creator
 * @export
 */
export const EducationPointCoverPictureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPictureGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EducationPointCoverPicture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPictureIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointCoverPictureIdDelete', 'id', id)
            const localVarPath = `/api/EducationPointCoverPicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPictureIdFileStreamGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointCoverPictureIdFileStreamGet', 'id', id)
            const localVarPath = `/api/EducationPointCoverPicture/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPictureIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointCoverPictureIdGet', 'id', id)
            const localVarPath = `/api/EducationPointCoverPicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointCoverPictureUpdateDto} [educationPointCoverPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPictureIdPatch: async (id: number, educationPointCoverPictureUpdateDto?: EducationPointCoverPictureUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointCoverPictureIdPatch', 'id', id)
            const localVarPath = `/api/EducationPointCoverPicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationPointCoverPictureUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointCoverPictureUpdateDto} [educationPointCoverPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPictureIdPut: async (id: number, educationPointCoverPictureUpdateDto?: EducationPointCoverPictureUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointCoverPictureIdPut', 'id', id)
            const localVarPath = `/api/EducationPointCoverPicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationPointCoverPictureUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPicturePost: async (file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EducationPointCoverPicture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EducationPointCoverPictureApi - functional programming interface
 * @export
 */
export const EducationPointCoverPictureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EducationPointCoverPictureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointCoverPictureGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EducationPointCoverPictureDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointCoverPictureGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointCoverPictureIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointCoverPictureIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointCoverPictureIdFileStreamGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointCoverPictureIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointCoverPictureIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointCoverPictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointCoverPictureIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointCoverPictureUpdateDto} [educationPointCoverPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointCoverPictureIdPatch(id: number, educationPointCoverPictureUpdateDto?: EducationPointCoverPictureUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointCoverPictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointCoverPictureIdPatch(id, educationPointCoverPictureUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointCoverPictureUpdateDto} [educationPointCoverPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointCoverPictureIdPut(id: number, educationPointCoverPictureUpdateDto?: EducationPointCoverPictureUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointCoverPictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointCoverPictureIdPut(id, educationPointCoverPictureUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointCoverPicturePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointCoverPictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointCoverPicturePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EducationPointCoverPictureApi - factory interface
 * @export
 */
export const EducationPointCoverPictureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EducationPointCoverPictureApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPictureGet(options?: any): AxiosPromise<Array<EducationPointCoverPictureDto>> {
            return localVarFp.apiEducationPointCoverPictureGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPictureIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEducationPointCoverPictureIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPictureIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEducationPointCoverPictureIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPictureIdGet(id: number, options?: any): AxiosPromise<EducationPointCoverPictureDetailsDto> {
            return localVarFp.apiEducationPointCoverPictureIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointCoverPictureUpdateDto} [educationPointCoverPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPictureIdPatch(id: number, educationPointCoverPictureUpdateDto?: EducationPointCoverPictureUpdateDto, options?: any): AxiosPromise<EducationPointCoverPictureDetailsDto> {
            return localVarFp.apiEducationPointCoverPictureIdPatch(id, educationPointCoverPictureUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointCoverPictureUpdateDto} [educationPointCoverPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPictureIdPut(id: number, educationPointCoverPictureUpdateDto?: EducationPointCoverPictureUpdateDto, options?: any): AxiosPromise<EducationPointCoverPictureDetailsDto> {
            return localVarFp.apiEducationPointCoverPictureIdPut(id, educationPointCoverPictureUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointCoverPicturePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<EducationPointCoverPictureDetailsDto> {
            return localVarFp.apiEducationPointCoverPicturePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EducationPointCoverPictureApi - object-oriented interface
 * @export
 * @class EducationPointCoverPictureApi
 * @extends {BaseAPI}
 */
export class EducationPointCoverPictureApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointCoverPictureApi
     */
    public apiEducationPointCoverPictureGet(options?: any) {
        return EducationPointCoverPictureApiFp(this.configuration).apiEducationPointCoverPictureGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointCoverPictureApi
     */
    public apiEducationPointCoverPictureIdDelete(id: number, options?: any) {
        return EducationPointCoverPictureApiFp(this.configuration).apiEducationPointCoverPictureIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointCoverPictureApi
     */
    public apiEducationPointCoverPictureIdFileStreamGet(id: number, options?: any) {
        return EducationPointCoverPictureApiFp(this.configuration).apiEducationPointCoverPictureIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointCoverPictureApi
     */
    public apiEducationPointCoverPictureIdGet(id: number, options?: any) {
        return EducationPointCoverPictureApiFp(this.configuration).apiEducationPointCoverPictureIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EducationPointCoverPictureUpdateDto} [educationPointCoverPictureUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointCoverPictureApi
     */
    public apiEducationPointCoverPictureIdPatch(id: number, educationPointCoverPictureUpdateDto?: EducationPointCoverPictureUpdateDto, options?: any) {
        return EducationPointCoverPictureApiFp(this.configuration).apiEducationPointCoverPictureIdPatch(id, educationPointCoverPictureUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EducationPointCoverPictureUpdateDto} [educationPointCoverPictureUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointCoverPictureApi
     */
    public apiEducationPointCoverPictureIdPut(id: number, educationPointCoverPictureUpdateDto?: EducationPointCoverPictureUpdateDto, options?: any) {
        return EducationPointCoverPictureApiFp(this.configuration).apiEducationPointCoverPictureIdPut(id, educationPointCoverPictureUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointCoverPictureApi
     */
    public apiEducationPointCoverPicturePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return EducationPointCoverPictureApiFp(this.configuration).apiEducationPointCoverPicturePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EducationPointPictureApi - axios parameter creator
 * @export
 */
export const EducationPointPictureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} educationPointSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureEducationPointSectionIdEducationPointSectionPicturesGet: async (educationPointSectionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationPointSectionId' is not null or undefined
            assertParamExists('apiEducationPointPictureEducationPointSectionIdEducationPointSectionPicturesGet', 'educationPointSectionId', educationPointSectionId)
            const localVarPath = `/api/EducationPointPicture/{educationPointSectionId}/educationPointSectionPictures`
                .replace(`{${"educationPointSectionId"}}`, encodeURIComponent(String(educationPointSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EducationPointPicture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointPictureIdDelete', 'id', id)
            const localVarPath = `/api/EducationPointPicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureIdFileStreamGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointPictureIdFileStreamGet', 'id', id)
            const localVarPath = `/api/EducationPointPicture/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointPictureIdGet', 'id', id)
            const localVarPath = `/api/EducationPointPicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointPictureUpdateDto} [educationPointPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureIdPatch: async (id: number, educationPointPictureUpdateDto?: EducationPointPictureUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointPictureIdPatch', 'id', id)
            const localVarPath = `/api/EducationPointPicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationPointPictureUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointPictureUpdateDto} [educationPointPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureIdPut: async (id: number, educationPointPictureUpdateDto?: EducationPointPictureUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointPictureIdPut', 'id', id)
            const localVarPath = `/api/EducationPointPicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationPointPictureUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureParagraphIdDirectionPatch: async (id: number, direction: Direction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointPictureParagraphIdDirectionPatch', 'id', id)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('apiEducationPointPictureParagraphIdDirectionPatch', 'direction', direction)
            const localVarPath = `/api/EducationPointPicture/paragraph/{id}/{direction}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"direction"}}`, encodeURIComponent(String(direction)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} educationPointSectionId 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPicturePositionIdEducationPointSectionIdDirectionPatch: async (id: number, educationPointSectionId: number, direction: Direction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointPicturePositionIdEducationPointSectionIdDirectionPatch', 'id', id)
            // verify required parameter 'educationPointSectionId' is not null or undefined
            assertParamExists('apiEducationPointPicturePositionIdEducationPointSectionIdDirectionPatch', 'educationPointSectionId', educationPointSectionId)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('apiEducationPointPicturePositionIdEducationPointSectionIdDirectionPatch', 'direction', direction)
            const localVarPath = `/api/EducationPointPicture/position/{id}/{educationPointSectionId}/{direction}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"educationPointSectionId"}}`, encodeURIComponent(String(educationPointSectionId)))
                .replace(`{${"direction"}}`, encodeURIComponent(String(direction)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [educationPointSectionId] 
         * @param {number} [position] 
         * @param {number} [paragraphsToSkip] 
         * @param {Array<LocalizableStringDto>} [description] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPicturePost: async (educationPointSectionId?: number, position?: number, paragraphsToSkip?: number, description?: Array<LocalizableStringDto>, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EducationPointPicture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (educationPointSectionId !== undefined) { 
                localVarFormParams.append('EducationPointSectionId', educationPointSectionId as any);
            }
    
            if (position !== undefined) { 
                localVarFormParams.append('Position', position as any);
            }
    
            if (paragraphsToSkip !== undefined) { 
                localVarFormParams.append('ParagraphsToSkip', paragraphsToSkip as any);
            }
                if (description) {
                localVarFormParams.append('Description', description.join(COLLECTION_FORMATS.csv));
            }

    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EducationPointPictureApi - functional programming interface
 * @export
 */
export const EducationPointPictureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EducationPointPictureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} educationPointSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointPictureEducationPointSectionIdEducationPointSectionPicturesGet(educationPointSectionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EducationPointPictureInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointPictureEducationPointSectionIdEducationPointSectionPicturesGet(educationPointSectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointPictureGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EducationPointPictureDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointPictureGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointPictureIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointPictureIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointPictureIdFileStreamGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointPictureIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointPictureIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointPictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointPictureIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointPictureUpdateDto} [educationPointPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointPictureIdPatch(id: number, educationPointPictureUpdateDto?: EducationPointPictureUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointPictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointPictureIdPatch(id, educationPointPictureUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointPictureUpdateDto} [educationPointPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointPictureIdPut(id: number, educationPointPictureUpdateDto?: EducationPointPictureUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointPictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointPictureIdPut(id, educationPointPictureUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointPictureParagraphIdDirectionPatch(id: number, direction: Direction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointPictureParagraphIdDirectionPatch(id, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} educationPointSectionId 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointPicturePositionIdEducationPointSectionIdDirectionPatch(id: number, educationPointSectionId: number, direction: Direction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EducationPointPictureInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointPicturePositionIdEducationPointSectionIdDirectionPatch(id, educationPointSectionId, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [educationPointSectionId] 
         * @param {number} [position] 
         * @param {number} [paragraphsToSkip] 
         * @param {Array<LocalizableStringDto>} [description] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointPicturePost(educationPointSectionId?: number, position?: number, paragraphsToSkip?: number, description?: Array<LocalizableStringDto>, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointPictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointPicturePost(educationPointSectionId, position, paragraphsToSkip, description, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EducationPointPictureApi - factory interface
 * @export
 */
export const EducationPointPictureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EducationPointPictureApiFp(configuration)
    return {
        /**
         * 
         * @param {number} educationPointSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureEducationPointSectionIdEducationPointSectionPicturesGet(educationPointSectionId: number, options?: any): AxiosPromise<Array<EducationPointPictureInfoDto>> {
            return localVarFp.apiEducationPointPictureEducationPointSectionIdEducationPointSectionPicturesGet(educationPointSectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureGet(options?: any): AxiosPromise<Array<EducationPointPictureDto>> {
            return localVarFp.apiEducationPointPictureGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEducationPointPictureIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEducationPointPictureIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureIdGet(id: number, options?: any): AxiosPromise<EducationPointPictureDetailsDto> {
            return localVarFp.apiEducationPointPictureIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointPictureUpdateDto} [educationPointPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureIdPatch(id: number, educationPointPictureUpdateDto?: EducationPointPictureUpdateDto, options?: any): AxiosPromise<EducationPointPictureDetailsDto> {
            return localVarFp.apiEducationPointPictureIdPatch(id, educationPointPictureUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointPictureUpdateDto} [educationPointPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureIdPut(id: number, educationPointPictureUpdateDto?: EducationPointPictureUpdateDto, options?: any): AxiosPromise<EducationPointPictureDetailsDto> {
            return localVarFp.apiEducationPointPictureIdPut(id, educationPointPictureUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPictureParagraphIdDirectionPatch(id: number, direction: Direction, options?: any): AxiosPromise<void> {
            return localVarFp.apiEducationPointPictureParagraphIdDirectionPatch(id, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} educationPointSectionId 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPicturePositionIdEducationPointSectionIdDirectionPatch(id: number, educationPointSectionId: number, direction: Direction, options?: any): AxiosPromise<Array<EducationPointPictureInfoDto>> {
            return localVarFp.apiEducationPointPicturePositionIdEducationPointSectionIdDirectionPatch(id, educationPointSectionId, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [educationPointSectionId] 
         * @param {number} [position] 
         * @param {number} [paragraphsToSkip] 
         * @param {Array<LocalizableStringDto>} [description] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointPicturePost(educationPointSectionId?: number, position?: number, paragraphsToSkip?: number, description?: Array<LocalizableStringDto>, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<EducationPointPictureDetailsDto> {
            return localVarFp.apiEducationPointPicturePost(educationPointSectionId, position, paragraphsToSkip, description, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EducationPointPictureApi - object-oriented interface
 * @export
 * @class EducationPointPictureApi
 * @extends {BaseAPI}
 */
export class EducationPointPictureApi extends BaseAPI {
    /**
     * 
     * @param {number} educationPointSectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointPictureApi
     */
    public apiEducationPointPictureEducationPointSectionIdEducationPointSectionPicturesGet(educationPointSectionId: number, options?: any) {
        return EducationPointPictureApiFp(this.configuration).apiEducationPointPictureEducationPointSectionIdEducationPointSectionPicturesGet(educationPointSectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointPictureApi
     */
    public apiEducationPointPictureGet(options?: any) {
        return EducationPointPictureApiFp(this.configuration).apiEducationPointPictureGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointPictureApi
     */
    public apiEducationPointPictureIdDelete(id: number, options?: any) {
        return EducationPointPictureApiFp(this.configuration).apiEducationPointPictureIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointPictureApi
     */
    public apiEducationPointPictureIdFileStreamGet(id: number, options?: any) {
        return EducationPointPictureApiFp(this.configuration).apiEducationPointPictureIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointPictureApi
     */
    public apiEducationPointPictureIdGet(id: number, options?: any) {
        return EducationPointPictureApiFp(this.configuration).apiEducationPointPictureIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EducationPointPictureUpdateDto} [educationPointPictureUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointPictureApi
     */
    public apiEducationPointPictureIdPatch(id: number, educationPointPictureUpdateDto?: EducationPointPictureUpdateDto, options?: any) {
        return EducationPointPictureApiFp(this.configuration).apiEducationPointPictureIdPatch(id, educationPointPictureUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EducationPointPictureUpdateDto} [educationPointPictureUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointPictureApi
     */
    public apiEducationPointPictureIdPut(id: number, educationPointPictureUpdateDto?: EducationPointPictureUpdateDto, options?: any) {
        return EducationPointPictureApiFp(this.configuration).apiEducationPointPictureIdPut(id, educationPointPictureUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Direction} direction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointPictureApi
     */
    public apiEducationPointPictureParagraphIdDirectionPatch(id: number, direction: Direction, options?: any) {
        return EducationPointPictureApiFp(this.configuration).apiEducationPointPictureParagraphIdDirectionPatch(id, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} educationPointSectionId 
     * @param {Direction} direction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointPictureApi
     */
    public apiEducationPointPicturePositionIdEducationPointSectionIdDirectionPatch(id: number, educationPointSectionId: number, direction: Direction, options?: any) {
        return EducationPointPictureApiFp(this.configuration).apiEducationPointPicturePositionIdEducationPointSectionIdDirectionPatch(id, educationPointSectionId, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [educationPointSectionId] 
     * @param {number} [position] 
     * @param {number} [paragraphsToSkip] 
     * @param {Array<LocalizableStringDto>} [description] 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointPictureApi
     */
    public apiEducationPointPicturePost(educationPointSectionId?: number, position?: number, paragraphsToSkip?: number, description?: Array<LocalizableStringDto>, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return EducationPointPictureApiFp(this.configuration).apiEducationPointPicturePost(educationPointSectionId, position, paragraphsToSkip, description, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EducationPointSectionApi - axios parameter creator
 * @export
 */
export const EducationPointSectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} educationPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionEducationPointIdEducationPointSectionsGet: async (educationPointId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'educationPointId' is not null or undefined
            assertParamExists('apiEducationPointSectionEducationPointIdEducationPointSectionsGet', 'educationPointId', educationPointId)
            const localVarPath = `/api/EducationPointSection/{educationPointId}/educationPointSections`
                .replace(`{${"educationPointId"}}`, encodeURIComponent(String(educationPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EducationPointSection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointSectionIdDelete', 'id', id)
            const localVarPath = `/api/EducationPointSection/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointSectionIdGet', 'id', id)
            const localVarPath = `/api/EducationPointSection/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointSectionUpdateDto} [educationPointSectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionIdPatch: async (id: number, educationPointSectionUpdateDto?: EducationPointSectionUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointSectionIdPatch', 'id', id)
            const localVarPath = `/api/EducationPointSection/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationPointSectionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointSectionUpdateDto} [educationPointSectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionIdPut: async (id: number, educationPointSectionUpdateDto?: EducationPointSectionUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEducationPointSectionIdPut', 'id', id)
            const localVarPath = `/api/EducationPointSection/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationPointSectionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EducationPointSectionCreateDto} [educationPointSectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionPost: async (educationPointSectionCreateDto?: EducationPointSectionCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EducationPointSection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(educationPointSectionCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EducationPointSectionApi - functional programming interface
 * @export
 */
export const EducationPointSectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EducationPointSectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} educationPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointSectionEducationPointIdEducationPointSectionsGet(educationPointId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EducationPointSectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointSectionEducationPointIdEducationPointSectionsGet(educationPointId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointSectionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EducationPointSectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointSectionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointSectionIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointSectionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointSectionIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointSectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointSectionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointSectionUpdateDto} [educationPointSectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointSectionIdPatch(id: number, educationPointSectionUpdateDto?: EducationPointSectionUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointSectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointSectionIdPatch(id, educationPointSectionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointSectionUpdateDto} [educationPointSectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointSectionIdPut(id: number, educationPointSectionUpdateDto?: EducationPointSectionUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointSectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointSectionIdPut(id, educationPointSectionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EducationPointSectionCreateDto} [educationPointSectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEducationPointSectionPost(educationPointSectionCreateDto?: EducationPointSectionCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EducationPointSectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEducationPointSectionPost(educationPointSectionCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EducationPointSectionApi - factory interface
 * @export
 */
export const EducationPointSectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EducationPointSectionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} educationPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionEducationPointIdEducationPointSectionsGet(educationPointId: number, options?: any): AxiosPromise<Array<EducationPointSectionDto>> {
            return localVarFp.apiEducationPointSectionEducationPointIdEducationPointSectionsGet(educationPointId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionGet(options?: any): AxiosPromise<Array<EducationPointSectionDto>> {
            return localVarFp.apiEducationPointSectionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEducationPointSectionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionIdGet(id: number, options?: any): AxiosPromise<EducationPointSectionDetailsDto> {
            return localVarFp.apiEducationPointSectionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointSectionUpdateDto} [educationPointSectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionIdPatch(id: number, educationPointSectionUpdateDto?: EducationPointSectionUpdateDto, options?: any): AxiosPromise<EducationPointSectionDetailsDto> {
            return localVarFp.apiEducationPointSectionIdPatch(id, educationPointSectionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EducationPointSectionUpdateDto} [educationPointSectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionIdPut(id: number, educationPointSectionUpdateDto?: EducationPointSectionUpdateDto, options?: any): AxiosPromise<EducationPointSectionDetailsDto> {
            return localVarFp.apiEducationPointSectionIdPut(id, educationPointSectionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EducationPointSectionCreateDto} [educationPointSectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEducationPointSectionPost(educationPointSectionCreateDto?: EducationPointSectionCreateDto, options?: any): AxiosPromise<EducationPointSectionDetailsDto> {
            return localVarFp.apiEducationPointSectionPost(educationPointSectionCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EducationPointSectionApi - object-oriented interface
 * @export
 * @class EducationPointSectionApi
 * @extends {BaseAPI}
 */
export class EducationPointSectionApi extends BaseAPI {
    /**
     * 
     * @param {number} educationPointId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointSectionApi
     */
    public apiEducationPointSectionEducationPointIdEducationPointSectionsGet(educationPointId: number, options?: any) {
        return EducationPointSectionApiFp(this.configuration).apiEducationPointSectionEducationPointIdEducationPointSectionsGet(educationPointId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointSectionApi
     */
    public apiEducationPointSectionGet(options?: any) {
        return EducationPointSectionApiFp(this.configuration).apiEducationPointSectionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointSectionApi
     */
    public apiEducationPointSectionIdDelete(id: number, options?: any) {
        return EducationPointSectionApiFp(this.configuration).apiEducationPointSectionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointSectionApi
     */
    public apiEducationPointSectionIdGet(id: number, options?: any) {
        return EducationPointSectionApiFp(this.configuration).apiEducationPointSectionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EducationPointSectionUpdateDto} [educationPointSectionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointSectionApi
     */
    public apiEducationPointSectionIdPatch(id: number, educationPointSectionUpdateDto?: EducationPointSectionUpdateDto, options?: any) {
        return EducationPointSectionApiFp(this.configuration).apiEducationPointSectionIdPatch(id, educationPointSectionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EducationPointSectionUpdateDto} [educationPointSectionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointSectionApi
     */
    public apiEducationPointSectionIdPut(id: number, educationPointSectionUpdateDto?: EducationPointSectionUpdateDto, options?: any) {
        return EducationPointSectionApiFp(this.configuration).apiEducationPointSectionIdPut(id, educationPointSectionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EducationPointSectionCreateDto} [educationPointSectionCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationPointSectionApi
     */
    public apiEducationPointSectionPost(educationPointSectionCreateDto?: EducationPointSectionCreateDto, options?: any) {
        return EducationPointSectionApiFp(this.configuration).apiEducationPointSectionPost(educationPointSectionCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MonumentApi - axios parameter creator
 * @export
 */
export const MonumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentEducationPointIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMonumentEducationPointIdGet', 'id', id)
            const localVarPath = `/api/Monument/educationPoint/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Monument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMonumentIdDelete', 'id', id)
            const localVarPath = `/api/Monument/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMonumentIdGet', 'id', id)
            const localVarPath = `/api/Monument/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MonumentUpdateDto} [monumentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIdPatch: async (id: number, monumentUpdateDto?: MonumentUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMonumentIdPatch', 'id', id)
            const localVarPath = `/api/Monument/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monumentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MonumentUpdateDto} [monumentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIdPut: async (id: number, monumentUpdateDto?: MonumentUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMonumentIdPut', 'id', id)
            const localVarPath = `/api/Monument/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monumentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentPositionIdDirectionPatch: async (id: number, direction: Direction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMonumentPositionIdDirectionPatch', 'id', id)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('apiMonumentPositionIdDirectionPatch', 'direction', direction)
            const localVarPath = `/api/Monument/position/{id}/{direction}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"direction"}}`, encodeURIComponent(String(direction)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MonumentCreateDto} [monumentCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentPost: async (monumentCreateDto?: MonumentCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Monument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monumentCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MonumentApi - functional programming interface
 * @export
 */
export const MonumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MonumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentEducationPointIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonumentReducedDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentEducationPointIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonumentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonumentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MonumentUpdateDto} [monumentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentIdPatch(id: number, monumentUpdateDto?: MonumentUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonumentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentIdPatch(id, monumentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MonumentUpdateDto} [monumentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentIdPut(id: number, monumentUpdateDto?: MonumentUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonumentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentIdPut(id, monumentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentPositionIdDirectionPatch(id: number, direction: Direction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonumentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentPositionIdDirectionPatch(id, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MonumentCreateDto} [monumentCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentPost(monumentCreateDto?: MonumentCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonumentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentPost(monumentCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MonumentApi - factory interface
 * @export
 */
export const MonumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MonumentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentEducationPointIdGet(id: number, options?: any): AxiosPromise<Array<MonumentReducedDto>> {
            return localVarFp.apiMonumentEducationPointIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentGet(options?: any): AxiosPromise<Array<MonumentDto>> {
            return localVarFp.apiMonumentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMonumentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIdGet(id: number, options?: any): AxiosPromise<MonumentDetailsDto> {
            return localVarFp.apiMonumentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MonumentUpdateDto} [monumentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIdPatch(id: number, monumentUpdateDto?: MonumentUpdateDto, options?: any): AxiosPromise<MonumentDetailsDto> {
            return localVarFp.apiMonumentIdPatch(id, monumentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MonumentUpdateDto} [monumentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIdPut(id: number, monumentUpdateDto?: MonumentUpdateDto, options?: any): AxiosPromise<MonumentDetailsDto> {
            return localVarFp.apiMonumentIdPut(id, monumentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Direction} direction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentPositionIdDirectionPatch(id: number, direction: Direction, options?: any): AxiosPromise<Array<MonumentDto>> {
            return localVarFp.apiMonumentPositionIdDirectionPatch(id, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MonumentCreateDto} [monumentCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentPost(monumentCreateDto?: MonumentCreateDto, options?: any): AxiosPromise<MonumentDetailsDto> {
            return localVarFp.apiMonumentPost(monumentCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MonumentApi - object-oriented interface
 * @export
 * @class MonumentApi
 * @extends {BaseAPI}
 */
export class MonumentApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentApi
     */
    public apiMonumentEducationPointIdGet(id: number, options?: any) {
        return MonumentApiFp(this.configuration).apiMonumentEducationPointIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentApi
     */
    public apiMonumentGet(options?: any) {
        return MonumentApiFp(this.configuration).apiMonumentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentApi
     */
    public apiMonumentIdDelete(id: number, options?: any) {
        return MonumentApiFp(this.configuration).apiMonumentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentApi
     */
    public apiMonumentIdGet(id: number, options?: any) {
        return MonumentApiFp(this.configuration).apiMonumentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MonumentUpdateDto} [monumentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentApi
     */
    public apiMonumentIdPatch(id: number, monumentUpdateDto?: MonumentUpdateDto, options?: any) {
        return MonumentApiFp(this.configuration).apiMonumentIdPatch(id, monumentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MonumentUpdateDto} [monumentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentApi
     */
    public apiMonumentIdPut(id: number, monumentUpdateDto?: MonumentUpdateDto, options?: any) {
        return MonumentApiFp(this.configuration).apiMonumentIdPut(id, monumentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Direction} direction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentApi
     */
    public apiMonumentPositionIdDirectionPatch(id: number, direction: Direction, options?: any) {
        return MonumentApiFp(this.configuration).apiMonumentPositionIdDirectionPatch(id, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MonumentCreateDto} [monumentCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentApi
     */
    public apiMonumentPost(monumentCreateDto?: MonumentCreateDto, options?: any) {
        return MonumentApiFp(this.configuration).apiMonumentPost(monumentCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MonumentIconApi - axios parameter creator
 * @export
 */
export const MonumentIconApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MonumentIcon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMonumentIconIdDelete', 'id', id)
            const localVarPath = `/api/MonumentIcon/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconIdFileStreamGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMonumentIconIdFileStreamGet', 'id', id)
            const localVarPath = `/api/MonumentIcon/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMonumentIconIdGet', 'id', id)
            const localVarPath = `/api/MonumentIcon/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MonumentIconUpdateDto} [monumentIconUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconIdPatch: async (id: number, monumentIconUpdateDto?: MonumentIconUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMonumentIconIdPatch', 'id', id)
            const localVarPath = `/api/MonumentIcon/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monumentIconUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MonumentIconUpdateDto} [monumentIconUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconIdPut: async (id: number, monumentIconUpdateDto?: MonumentIconUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMonumentIconIdPut', 'id', id)
            const localVarPath = `/api/MonumentIcon/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monumentIconUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconPost: async (file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MonumentIcon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MonumentIconApi - functional programming interface
 * @export
 */
export const MonumentIconApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MonumentIconApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentIconGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonumentIconDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentIconGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentIconIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentIconIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentIconIdFileStreamGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentIconIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentIconIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonumentIconDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentIconIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MonumentIconUpdateDto} [monumentIconUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentIconIdPatch(id: number, monumentIconUpdateDto?: MonumentIconUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonumentIconDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentIconIdPatch(id, monumentIconUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MonumentIconUpdateDto} [monumentIconUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentIconIdPut(id: number, monumentIconUpdateDto?: MonumentIconUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonumentIconDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentIconIdPut(id, monumentIconUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMonumentIconPost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonumentIconDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMonumentIconPost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MonumentIconApi - factory interface
 * @export
 */
export const MonumentIconApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MonumentIconApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconGet(options?: any): AxiosPromise<Array<MonumentIconDto>> {
            return localVarFp.apiMonumentIconGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMonumentIconIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMonumentIconIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconIdGet(id: number, options?: any): AxiosPromise<MonumentIconDetailsDto> {
            return localVarFp.apiMonumentIconIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MonumentIconUpdateDto} [monumentIconUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconIdPatch(id: number, monumentIconUpdateDto?: MonumentIconUpdateDto, options?: any): AxiosPromise<MonumentIconDetailsDto> {
            return localVarFp.apiMonumentIconIdPatch(id, monumentIconUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MonumentIconUpdateDto} [monumentIconUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconIdPut(id: number, monumentIconUpdateDto?: MonumentIconUpdateDto, options?: any): AxiosPromise<MonumentIconDetailsDto> {
            return localVarFp.apiMonumentIconIdPut(id, monumentIconUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMonumentIconPost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<MonumentIconDetailsDto> {
            return localVarFp.apiMonumentIconPost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MonumentIconApi - object-oriented interface
 * @export
 * @class MonumentIconApi
 * @extends {BaseAPI}
 */
export class MonumentIconApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentIconApi
     */
    public apiMonumentIconGet(options?: any) {
        return MonumentIconApiFp(this.configuration).apiMonumentIconGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentIconApi
     */
    public apiMonumentIconIdDelete(id: number, options?: any) {
        return MonumentIconApiFp(this.configuration).apiMonumentIconIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentIconApi
     */
    public apiMonumentIconIdFileStreamGet(id: number, options?: any) {
        return MonumentIconApiFp(this.configuration).apiMonumentIconIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentIconApi
     */
    public apiMonumentIconIdGet(id: number, options?: any) {
        return MonumentIconApiFp(this.configuration).apiMonumentIconIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MonumentIconUpdateDto} [monumentIconUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentIconApi
     */
    public apiMonumentIconIdPatch(id: number, monumentIconUpdateDto?: MonumentIconUpdateDto, options?: any) {
        return MonumentIconApiFp(this.configuration).apiMonumentIconIdPatch(id, monumentIconUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MonumentIconUpdateDto} [monumentIconUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentIconApi
     */
    public apiMonumentIconIdPut(id: number, monumentIconUpdateDto?: MonumentIconUpdateDto, options?: any) {
        return MonumentIconApiFp(this.configuration).apiMonumentIconIdPut(id, monumentIconUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonumentIconApi
     */
    public apiMonumentIconPost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return MonumentIconApiFp(this.configuration).apiMonumentIconPost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrailApi - axios parameter creator
 * @export
 */
export const TrailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrailGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Trail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrailIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTrailIdDelete', 'id', id)
            const localVarPath = `/api/Trail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrailIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTrailIdGet', 'id', id)
            const localVarPath = `/api/Trail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TrailUpdateDto} [trailUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrailIdPatch: async (id: number, trailUpdateDto?: TrailUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTrailIdPatch', 'id', id)
            const localVarPath = `/api/Trail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trailUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TrailUpdateDto} [trailUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrailIdPut: async (id: number, trailUpdateDto?: TrailUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTrailIdPut', 'id', id)
            const localVarPath = `/api/Trail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trailUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TrailCreateDto} [trailCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrailPost: async (trailCreateDto?: TrailCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Trail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trailCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrailApi - functional programming interface
 * @export
 */
export const TrailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrailGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrailDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrailGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrailIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrailIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrailIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrailDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrailIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TrailUpdateDto} [trailUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrailIdPatch(id: number, trailUpdateDto?: TrailUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrailDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrailIdPatch(id, trailUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TrailUpdateDto} [trailUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrailIdPut(id: number, trailUpdateDto?: TrailUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrailDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrailIdPut(id, trailUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TrailCreateDto} [trailCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrailPost(trailCreateDto?: TrailCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrailDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrailPost(trailCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrailApi - factory interface
 * @export
 */
export const TrailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrailApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrailGet(options?: any): AxiosPromise<Array<TrailDto>> {
            return localVarFp.apiTrailGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrailIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiTrailIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrailIdGet(id: number, options?: any): AxiosPromise<TrailDetailsDto> {
            return localVarFp.apiTrailIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TrailUpdateDto} [trailUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrailIdPatch(id: number, trailUpdateDto?: TrailUpdateDto, options?: any): AxiosPromise<TrailDetailsDto> {
            return localVarFp.apiTrailIdPatch(id, trailUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TrailUpdateDto} [trailUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrailIdPut(id: number, trailUpdateDto?: TrailUpdateDto, options?: any): AxiosPromise<TrailDetailsDto> {
            return localVarFp.apiTrailIdPut(id, trailUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TrailCreateDto} [trailCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrailPost(trailCreateDto?: TrailCreateDto, options?: any): AxiosPromise<TrailDetailsDto> {
            return localVarFp.apiTrailPost(trailCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrailApi - object-oriented interface
 * @export
 * @class TrailApi
 * @extends {BaseAPI}
 */
export class TrailApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrailApi
     */
    public apiTrailGet(options?: any) {
        return TrailApiFp(this.configuration).apiTrailGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrailApi
     */
    public apiTrailIdDelete(id: number, options?: any) {
        return TrailApiFp(this.configuration).apiTrailIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrailApi
     */
    public apiTrailIdGet(id: number, options?: any) {
        return TrailApiFp(this.configuration).apiTrailIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TrailUpdateDto} [trailUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrailApi
     */
    public apiTrailIdPatch(id: number, trailUpdateDto?: TrailUpdateDto, options?: any) {
        return TrailApiFp(this.configuration).apiTrailIdPatch(id, trailUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TrailUpdateDto} [trailUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrailApi
     */
    public apiTrailIdPut(id: number, trailUpdateDto?: TrailUpdateDto, options?: any) {
        return TrailApiFp(this.configuration).apiTrailIdPut(id, trailUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TrailCreateDto} [trailCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrailApi
     */
    public apiTrailPost(trailCreateDto?: TrailCreateDto, options?: any) {
        return TrailApiFp(this.configuration).apiTrailPost(trailCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


