import { Editor } from 'react-draft-wysiwyg';
import { useEffect, useState } from 'react';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import classNames from 'classnames';

const formatPastedText = (text: string, html: string) => {
    var internalElement = document.createElement( 'div' );
    internalElement.innerHTML = html;
    
    return {
        html: internalElement.innerHTML + `
        <div class="" data-block="true" data-editor="2djoe" data-offset-key="3ddv8-0-0"
    style="box-sizing: border-box; color: rgb(99, 99, 99); font-family: Lato, -apple-system, &quot;system-ui&quot;, &quot;Segoe UI&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
    <div data-offset-key="3ddv8-0-0" class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"
        style="box-sizing: border-box; margin: 1em 0px; position: relative; white-space: pre-wrap; direction: ltr; text-align: left;">
        <span data-offset-key="3ddv8-0-0" style="box-sizing: border-box;"><span data-text="true"
                style="box-sizing: border-box;">&zwnj;</span></span></div>
</div>`,
        text
    };    
}

function getEditorStateFromHtml(value?: string): EditorState | undefined {

    if (!value) {
        return;
    }

    const contentBlock = htmlToDraft(value);

    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
}

export default function RichtextEditor(props: RichtextEditorProps) {
    const [editorState, setEditorState] = useState(getEditorStateFromHtml(props.value));
    const [currentHtmlValue, setCurrentHtmlValue] = useState(props.value);

    useEffect(() => {
        if (props.value !== currentHtmlValue) {
            setEditorState(getEditorStateFromHtml(props.value));
            setCurrentHtmlValue(props.value);
        }
    }, [props.value, currentHtmlValue]);

    const onContentStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        props.onChange?.(html);
        setCurrentHtmlValue(html);
    };

    return <Editor
        editorState={editorState}
        onEditorStateChange={onContentStateChange}
        editorClassName={props.style?.editor ? classNames("ant-input", props.style.editor) : "ant-input"}
        localization={props.localization}
        toolbar={props.toolbar}
        handlePastedText={() => false}
        formatPastedText={formatPastedText}
        {...props?.editorProps}
    />;
}

interface RichtextEditorProps {
    value?: string,
    onChange?: (value: string) => void,
    style?: any,
    localization? : any,
    toolbar?: any,
    editorProps?: any
}
