import {Select} from "antd";
import React from "react";
import {useSelector} from "react-redux";
import styles from './languageSelector.module.less';
import {getGlobalLanguage} from "../../../redux/languageSlice";

const {Option} = Select;

export type Language = string;

export default function LanguageSelector(props: LanguageSelectorProps) {
    const defaultLanguage = useSelector(getGlobalLanguage);

    return <Select
        className={styles.languageSelector}
        key={defaultLanguage}
        bordered={props.bordered ?? true}
        disabled={props.loading || props.disabled}
        loading={props.loading}
        defaultValue={defaultLanguage || ""}
        onSelect={props.onSelect}
    >
        <Option value={"pl-PL"}>pl-PL</Option>
        <Option value={"en-GB"}>en-GB</Option>
    </Select>;
}

interface LanguageSelectorProps {
    onSelect: (value: string) => void,
    disabled?: boolean,
    loading?: boolean,
    bordered?: boolean
}
