import { createSlice } from "@reduxjs/toolkit";

const initialState: {
    globalLanguage: string
} = {
    globalLanguage: "pl-PL",
};

const languageSlice = createSlice({
    name: "languageSlice",
    initialState,
    reducers: {
        setGlobalLanguage: (state, action) => {
            state.globalLanguage = action.payload;
        }
    }
});

export const { setGlobalLanguage } = languageSlice.actions;

export const getGlobalLanguage = (state: any) => state.language.globalLanguage;

export default languageSlice.reducer;
