import React from "react";
import {loadingSpin} from "./routingContainer";
import {AuthInitialized, Protected} from "./routingWrappers";

const Main = React.lazy(() => import("../components/mainPage"));
const Login = React.lazy(() => import( "../components/loginPage"));
const Users = React.lazy(() => import("../applicationUser/applicationUserListContainer"));

const ForgotPassword = React.lazy(() => import("../components/forgotPassword"));
const ForgotPasswordComplete = React.lazy(() => import("../components/forgotPasswordComplete"))
const Activate = React.lazy(() => import("../authentication/activate/activatePage"));
const EducationPoint = React.lazy(() => import("../educationPoint/educationPointListContainer"));
const EducationPointPictures = React.lazy(() => import("../educationPointPicture/educationPointPictureListContainer"));
const Trail = React.lazy(() => import("../trail/trailListContainer"));
const EducationPointSection = React.lazy(() => import("../educationPointSection/educationPointSectionListContainer"));

export const LoginPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Login/>
</AuthInitialized>;

export const ForgotPasswordPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <ForgotPassword/>
</AuthInitialized>;

export const ForgotPasswordCompletePage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <ForgotPasswordComplete/>
</AuthInitialized>

export const ActivatePage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Activate/>
</AuthInitialized>

export const UsersPage = () => <Protected loadingIndicator={loadingSpin}>
    <Users/>
</Protected>

export const MainPage = () => <Protected loadingIndicator={loadingSpin}>
    <Main/>
</Protected>

export const EducationPointPage = () => <Protected loadingIndicator={loadingSpin}>
    <EducationPoint/>
</Protected>

export const EducationPointPicturesPage = () => <Protected loadingIndicator={loadingSpin}>
    <EducationPointPictures/>
</Protected>

export const TrailPage = () => <Protected loadingIndicator={loadingSpin}>
    <Trail/>
</Protected>

export const EducationPointSectionPage = () => <Protected loadingIndicator={loadingSpin}>
    <EducationPointSection/>
</Protected>

const MonumentTableContainer = React.lazy(() => import("../monument/monumentListContainer"));

export const MonumentPage = () => <Protected loadingIndicator={loadingSpin}>
    <MonumentTableContainer />
</Protected>

const MonumentFormContainer = React.lazy(() => import("../monument/monumentForm/monumentFormContainer"));

export const MonumentFormPage = () => <Protected loadingIndicator={loadingSpin}>
    <MonumentFormContainer />
</Protected>
