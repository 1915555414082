import {CompassOutlined, ForkOutlined, PlusSquareOutlined, UserOutlined} from "@ant-design/icons";
import {Menu} from "antd";
import {NavLink} from "react-router-dom";
import {RoutingPaths} from "../../routing/routingContainer";
import styles from "./siderMenu.module.less"
import {useCurrentRoutingPath} from "digimuth-components/dist";
import React from "react";

export default function SiderMenu() {
    const route = useCurrentRoutingPath(RoutingPaths)?.route || "";

    return <>
        <NavLink to={"/"}>
            <h3 className={styles.logo}>Ekopunkty CMS</h3>
        </NavLink>
        <Menu theme="dark" selectedKeys={[route]} mode="inline">
            <Menu.Item key={RoutingPaths.users.route} icon={<UserOutlined/>}>
                <NavLink to={RoutingPaths.users.route}>
                    Użytkownicy
                </NavLink>
            </Menu.Item>
            <Menu.Item key={RoutingPaths.educationPointTable.route} icon={<PlusSquareOutlined/>}>
                <NavLink to={RoutingPaths.educationPointTable.route}>
                    Punkty edukacyjne
                </NavLink>
            </Menu.Item>
            <Menu.Item key={RoutingPaths.trailsTable.route} icon={<ForkOutlined/>}>
                <NavLink to={RoutingPaths.trailsTable.route}>
                    Szlaki turystyczne
                </NavLink>
            </Menu.Item>
            <Menu.Item key={RoutingPaths.monumentTable.route} icon={<CompassOutlined/>}>
                <NavLink to={RoutingPaths.monumentTable.route}>
                    Zabytki
                </NavLink>
            </Menu.Item>
        </Menu>
    </>;
}
