import React from "react";
import {Route, Routes} from "react-router-dom";
import {RoutingDefinition} from "digimuth-components/dist/components/crudBreadcrumb/crudBreadcrumb";
import styles from "./routingContainer.module.less";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import * as Pages from "./pages";

const mainPage = {route: "/", name: "Strona główna"};
const users = {route: "/users", name: "Użytkownicy"}
const educationPointTable = {route: "/educationPoints", name: "Punkty edukacyjne"}
const educationPointSectionTable = {
    route: "/educationPoint/:educationPointId/sections",
    name: "Sekcje punktu edukacyjnego"
}

export const RoutingPaths: { [key: string]: RoutingDefinition } = {
    monumentAdd: { route: "/monument/add" },
    monumentEdit: { route: "/monument/:id/edit" },
    monumentTable: { route: "/monument" },

    login: {route: "/login"},
    activate: {route: "/activate"},
    forgotPassword: {route: "/forgotPassword"},
    forgotPasswordComplete: {route: "/forgotPasswordComplete"},
    users: {route: "/users", breadcrumbEntries: [mainPage, users]},
    educationPointTable: {...educationPointTable, breadcrumbEntries: [mainPage, educationPointTable]},
    educationPointPicturesTable: {
        route: "/educationPointSection/:educationPointSectionId/images",
        breadcrumbEntries: [mainPage, educationPointTable, educationPointSectionTable, {name: "Zdjęcia punktu"}]
    },
    educationPointSectionTable: {
        ...educationPointSectionTable,
        breadcrumbEntries: [mainPage, educationPointTable, educationPointSectionTable]
    },
    trailsTable: {route: "/trails", breadcrumbEntries: [mainPage, {name: "Szlaki turystyczne"}]},
};

const loadingIcon = <LoadingOutlined className={styles.loadingIcon} spin/>;
export const loadingSpin = <Spin className={styles.loadingContainer} indicator={loadingIcon}/>;

export default function RoutingContainer() {
    return <Routes>
        <Route path={RoutingPaths.monumentAdd.route} element={<Pages.MonumentFormPage />} />
        <Route path={RoutingPaths.monumentEdit.route} element={<Pages.MonumentFormPage />} />
        <Route path={RoutingPaths.monumentTable.route} element={<Pages.MonumentPage />} />

        <Route path={RoutingPaths.login.route} element={<Pages.LoginPage />} />
        <Route path={RoutingPaths.users.route} element={<Pages.UsersPage />} />
        <Route path={RoutingPaths.forgotPasswordComplete.route} element={<Pages.ForgotPasswordCompletePage/>}/>
        <Route path={RoutingPaths.activate.route} element={<Pages.ActivatePage/>}/>
        <Route path={RoutingPaths.forgotPassword.route} element={<Pages.ForgotPasswordPage/>}/>
        <Route path={RoutingPaths.forgotPasswordComplete.route} element={<Pages.ForgotPasswordCompletePage/>}/>
        <Route path={RoutingPaths.activate.route} element={<Pages.ActivatePage/>}/>
        <Route path={RoutingPaths.educationPointTable.route} element={<Pages.EducationPointPage/>}/>
        <Route path={RoutingPaths.educationPointPicturesTable.route} element={<Pages.EducationPointPicturesPage/>}/>
        <Route path={RoutingPaths.trailsTable.route} element={<Pages.TrailPage/>}/>
        <Route path={RoutingPaths.educationPointSectionTable.route} element={<Pages.EducationPointSectionPage/>}/>
        <Route path={"/"} element={<Pages.MainPage/>}/>
    </Routes>
}
