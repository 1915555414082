import {Button, ButtonProps} from "antd";
import React, {useState} from "react";

export default function AsyncButton(props: ButtonProps) {
    const [isLoading, setIsLoading] = useState(false);

    const onClick = async (event: any) => {
        setIsLoading(true);
        try {
            await props.onClick?.(event);
        } finally {
            setIsLoading(false);
        }
    };

    return <Button {...props} onClick={onClick} loading={isLoading || props.loading} disabled={isLoading || props.disabled}/>
}