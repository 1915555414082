import React from 'react';
import { useAuth } from "oidc-react";
import HeaderComponent from "./header";
import {useNavigate} from "react-router-dom";

export default function HeaderContainer() {
    const auth = useAuth();
    const navigate = useNavigate();

    const onLogoutPressed = async () => {
        await auth.signOut();
        await auth.signOutRedirect();
    }

    const onLoginPressed = async () => {
        navigate("/login");
    }

    return <HeaderComponent
        onLogoutPressed={onLogoutPressed}
        userData={auth.userData}
        onLoginPressed={onLoginPressed}
    />
}
