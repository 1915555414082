import {configureStore} from "@reduxjs/toolkit";
import alertReducer from './alertSlice';
import applicationUserSlice from "./applicationUserSlice";
import languageSlice from "./languageSlice";

export const store = configureStore({
    reducer: {
        alert: alertReducer,
        applicationUser: applicationUserSlice,
        language: languageSlice
    }
});
