import { createSlice } from "@reduxjs/toolkit";
import { ApplicationUserDetailsDto } from "../api";

const initialState: { currentUser: ApplicationUserDetailsDto | null } = {
    currentUser: null
};

const applicationUserSlice = createSlice({
    name: 'applicationUserSlice',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        }
    }
});

// these methods only modify the state
export const { setCurrentUser } = applicationUserSlice.actions;

// these methods only read the state
export const getCurrentUser = (state: any) => state.applicationUser.currentUser as ApplicationUserDetailsDto | null;

export default applicationUserSlice.reducer;
